/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import LoginService from '../services/login/sso.service';
import loginSSOStyle from './loginSSO.module.scss';
import PropTypes from 'prop-types';
import logo from '../../logo.png';
import Spinner from '../../components/spinner/Spinner';
import { useUser } from '../../contexts/User';
import configs from '../../configs';
import Notify from '../../assets/notify.png';
import moment from 'moment';
import { DataDogRum } from '../../utils/dataDogRum';
import { loginRequest } from '../../utils/authConfig';
// import { callMsGraph } from '@/graph';
import { useMsal } from '@azure/msal-react';
import { Base64 } from 'js-base64';
import uuid from 'react-uuid';
import { useTourMask } from '../../components/tourMask/tourMaskHooks';

const LoginSSO = props => {
  const loginService = new LoginService();

  const { i18n } = useTranslation();
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const { handleUser, handleLogged } = useUser();
  const [showNotify, setShowNotify] = useState(true)
  const { instance, accounts, inProgress } = useMsal();
  const { handleIsOpenSum } = useTourMask();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0].username && inProgress === 'none') {
      setLoading(true);
      const response = accounts[0];

      loginService.loginAuth({
        str_email: response.username,
        str_token: response.idToken,
        response: JSON.stringify(response)
      }).then((res) => {
        if (res.type === 'success') {
          assertLogin(res.results);
        } else {
          alert(res.message)
          instance.logoutRedirect({
            postLogoutRedirectUri: '/',
          });
        }
        setLoading(false);
      })
    }
  }, [accounts, inProgress]);

  useEffect(() => {
    setTimeout(() => {
      handleIsOpenSum();
    }, 1000)
  }, [])
  const RequestProfileData = () => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
  };
  const handleLogin = async () => {
    if (inProgress === 'none') {
      try {
        setLoading(true);
        await instance.loginRedirect(loginRequest);
        RequestProfileData();
      } catch (error) {
        console.log('Login error:', error);
        setLoading(false);
      }
    }
  };

  const assertLogin = async ssoResult => {
    if (ssoResult && ssoResult) {
      const showMenuTemp = !!(
        ssoResult.arr_privileges &&
        ssoResult.arr_privileges.length > 0
        && !(ssoResult?.arr_privileges.length === 1
          && ssoResult?.arr_privileges[0] === 'TASTER')
      );
      // Save user session
      const login_date = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
      // const unique_id = ssoResult.saml_response_result.user.attributes['http://schemas.microsoft.com/identity/claims/objectidentifier'][0]
      let userTemp = {
        ...ssoResult,
        currentTab: 'menu_tasting_session',
        currentMenu: 'menu_tasting_session',
        showMenu: showMenuTemp,
        login_date,
      };

      await handleUser(userTemp);
      handleLogged();
      i18n.changeLanguage(ssoResult.str_language);
      DataDogRum(userTemp)
    } else {
      // Show error message with user SSO email (Ex.: User "example@ab-inbev.com" is not allowed to access.)
      setInvalid('email');
      setLoading(false);
      setEmail(ssoResult.str_email);
    }
  };

  return (
    <div className={loginSSOStyle.loginContainer}>
      {showNotify &&
        <div className={loginSSOStyle.notice}>
          <img src={Notify} alt="" srcset="" />
        </div>
      }
      <div className={loginSSOStyle.loginBox} style={{ borderRadius: showNotify ? '0 10px 10px 0' : '10px' }}>
        <div className={loginSSOStyle.loginLogo}>
          <img src={logo} alt="ABInBev" height="50" />
        </div>
        {invalid == 'email' ? (
          <span className={loginSSOStyle.invalid}>
            <b>Error!</b>
            <br />
            User "{email}" is not allowed to use this application!
          </span>
        ) : null}
        {invalid == 'timeout' ? (
          <span className={loginSSOStyle.invalid}>
            <b>Error!</b>
            <br />
            SSO timeout, please try again!
          </span>
        ) : null}
        {loading ? (
          <Spinner />
        ) : (
          <Button Ids='sso' label="SSO Login" onClick={handleLogin} confirm />
        )}
        {process.env.REACT_APP_STAGE !== 'prod' ? (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12', }}
          >
            TEST ENVIRONMENT &nbsp; v<b>{configs.val_version}</b>
          </div>
        ) : (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12' }}
          >
            v<b>{configs.val_version}</b>
          </div>
        )}
      </div>
    </div >
  );
};


export default LoginSSO;